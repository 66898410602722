<template>
  <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
    <div class="approvalOfFinancialTitle">
      <DataCard
        :data="allFilters"
        :fields="fields"
        :actions="actions"
        @approve="approveFinancialTitle"
        @reject="rejectFinancialTitle"
        @cardClick="detailFinancialTitle"
        @filterR="filterByRequesterName"
        @filterB="filterByBranchCode"
        filterData
      />
    </div>
    <JustifiedFinancialTitle ref="JustifiedFinancialTitle" />
  </q-pull-to-refresh>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataCard from "../../components/DataCard";
import JustifiedFinancialTitle from "./justifiedFinancialTitle";
import formatString from "../../helpers/formatString";

export default {
  components: {
    DataCard,
    JustifiedFinancialTitle
  },
  data() {
    return {
      fields: [
        {
          field: "supplier",
          label: "",
          type: "string",
          class: "text-bold"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "financialTitle",
          label: "",
          type: "string",
          class: "text-body2 text-italic"
        },
        {
          field: "branch",
          label: "",
          type: "string",
          class: "text-body2"
        },
        {
          field: "type",
          label: "",
          type: "string",
          class: "text-overline"
        },
        {
          field: "issueDate",
          label: "",
          type: "string",
          class: "text-overline"
        }
      ],
      actions: [
        {
          label: "Aprovar",
          color: "green",
          icon: "check",
          eventEmit: "approve"
        },
        {
          label: "Rejeitar",
          color: "red",
          icon: "block",
          eventEmit: "reject"
        }
      ],
      filterWebR: null,
      filterWebB: null,
      filterR: sessionStorage.filterWebR,
      filterB: sessionStorage.filterWebB
    };
  },
  computed: {
    ...mapState("financialtitle", ["financialtitle"]),

    allFilters() {
      let financialtitle = this.financialtitle;

      switch (sessionStorage.sortWeb) {
        case "sSmLg":
          financialtitle.sort((a, b) => a.amount - b.amount);
          break;
        case "sLgSm":
          financialtitle.sort((a, b) => b.amount - a.amount);
          break;
        case "sNewOld":
          financialtitle.sort((a, b) => b.issue - a.issue);
          break;
        case "sOldNew":
          financialtitle.sort((a, b) => a.issue - b.issue);
          break;
      }

      if (this.filterR) {
        financialtitle = financialtitle.filter(fin => {
          return (
            fin.title.trim().indexOf(this.filterR.trim()) >= 0 ||
            fin.supplierCode.trim().indexOf(this.filterR.trim()) >= 0 ||
            fin.supplierName
              .toUpperCase()
              .indexOf(this.filterR.toUpperCase()) >= 0 ||
            fin.branch.trim().indexOf(this.filterR.trim()) >= 0
          );
        });
      }

      if (this.filterB) {
        if (sessionStorage.filterWebB) {
          financialtitle = financialtitle.filter(fin => {
            return (
              fin.branch.trim().indexOf(sessionStorage.filterWebB.trim()) >= 0
            );
          });
        } else {
          financialtitle = financialtitle.filter(fin => {
            return fin.branch.trim().indexOf(this.filterB.trim()) >= 0;
          });
        }
      }

      return financialtitle.map(fin => {
        var nameRequesterFirstLevel = "";
        fin.approvalHierarchy.forEach(lvl => {
          if (lvl.level === "01") {
            nameRequesterFirstLevel = lvl.approver;
          }
        });

        const data = {
          id: fin.id,
          financialTitle: `Título: ${fin.title}`,
          title: fin.title,
          supplier: `${fin.supplierCode} - ${fin.supplierName}`,
          supplierCode: fin.supplierCode,
          supplierName: fin.supplierName,
          branch: `${fin.branch} - ${fin.branchName}`,
          branchCode: fin.branch,
          branchName: fin.branchName,
          nameApprovalFirstLevel: nameRequesterFirstLevel,
          issue: formatString(fin.issue, "date"),
          actualDueDate: formatString(fin.actualDueDate, "date"),
          type: fin.type,
          level: fin.level,
          status: fin.status,
          costCenter: fin.costCenter,
          observation: fin.observation,
          amount: fin.amount,
          installment: fin.installment
        };

        return data;
      });
    }
  },
  methods: {
    ...mapActions("financialtitle", [
      "getFinancialTitle",
      "approvesRejectsJustifysFinancialTitles"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    approveFinancialTitle(FinancialTitle) {
      this.sendResponse(
        FinancialTitle,
        "approved",
        "APROVAR",
        "text-green text-bold"
      );
    },
    rejectFinancialTitle(FinancialTitle) {
      this.sendResponse(
        FinancialTitle,
        "rejected",
        "REJEITAR",
        "text-red text-bold"
      );
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Título Financeiro ${request.title}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            if (response === "rejected") {
              return this.$refs.JustifiedFinancialTitle.open(
                request,
                response,
                text,
                classMessage
              );
            }

            const reasonReject =
              response === "rejected" ? this.descriptionRejection : "approved";
            await this.approvesRejectsJustifysFinancialTitles({
              request,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });

            this.getFinancialTitle();
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    detailFinancialTitle(financialTitleId) {
      this.$router.push({
        name: "financialTitleDetail",
        params: { financialTitleId }
      });
    },
    filterByRequesterName(filterR) {
      this.filterR = filterR;
    },
    filterByBranchCode(filterB) {
      if (sessionStorage.filterWebB && !filterB) {
        this.filterB = sessionStorage.filterWebB;
      } else {
        this.filterB = filterB;
        sessionStorage.setItem("filterWebB", filterB);
      }
    },
    async refresh(done) {
      await this.getFinancialTitle();
      done();
    }
  },
  created() {
    this.getFinancialTitle();
  }
};
</script>

<style lang="scss">
.approvalOfFinancialTitle {
  width: 100%;
}
</style>
